.grid-custom{
    padding:0;
    margin-right: 10px;
}
.grid-custom2{
    padding:0;
    margin-right: -10px;
}
.banner-search-checkbox{
    font-weight: bold;
    font-size:18px;
    color:white;
    font-family: auto;
    margin-left:10px
}

@media screen  and (max-width:992px){
    .grid-custom2{
        padding:0;
        margin-right: 0px !important;
    }
}