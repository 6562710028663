.b32 {
    position: relative !important;
    padding-bottom: 100px !important;
    /* height: 100% !important; hafeez commit */
}

.but2 {
    position: relative !important;
    display: block ;
}

.but2 {
    position: absolute !important;
    width: 90%;
    bottom: 20px !important;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.but2 input {
}

.but2 input {
    margin-bottom: 12px;
}

@media (min-width: 200px) and (max-width: 750px) {
    .abcd{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .para_size{
        margin-top: 20px !important;
        font-size: 15px !important;
    }
    /* Add any additional styles for screens within this range here */
  }


  @media (min-width: 1230px) and (max-width: 1391px) {
    .b32{
        height: 500px !important;
    }
    #last_para{
        padding-top: 22px !important;
        background-color: "red";
    }
  }
  

  @media (min-width: 1205px) and (max-width: 1222px) {
    .b32{
        height: 500px !important;
    }
  }
  